import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../../../components/layout"
import Seo from "../../../../components/Seo"
import Button from "../../../../components/Button"
import {
  PrimaryHero,
  HeroCopyBlock,
} from "../../../../components/Heros"
import Spacer from "../../../../components/Spacer"
import Popup from "../../../../components/Popup"

import styles from "./styles.module.scss"
import arrow from "./images/arrow-line.svg"

const Jobs = ({ data }) => {
  const [viewJob, setViewJob] = useState(false);
  const [viewJobItem, setViewJobItem] = useState(null);

  return (
    <Layout>
      <Seo title="Sorted | Jobs" keywords={[`Jobs`]} />
      <PrimaryHero theme="pink">
        <HeroCopyBlock
          title={["Reckon you're,", "the Sorted sort?"]}
        >
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
        </HeroCopyBlock>
      </PrimaryHero>

      <Spacer size="medium" sides="topbottom">
        <h2 class="text-pink text-center">Take a look at our current job openings below.</h2>
      </Spacer>

      <div className="container-thin">
        <ul className={styles.joblistings}>
          {data.allWorkableJob.edges.map(item => (
            <li
              key={item.node.url}
              onClick={() => {
                setViewJob(true);
                setViewJobItem(item.node)
              }}
            >
              <p href={item.node.url}>{ item.node.title }</p>
              <img className={styles.arrowRight} src={arrow} alt="view job arrow" />
            </li>
          ))}
        </ul>
      </div>
      {viewJob && (
        <Popup
          close={() => {
            setViewJob(false);
            setViewJobItem(null);
          }}
        >
          <h3>{viewJobItem.title}</h3>
          <p className={styles.jobListingSmall}>{`${viewJobItem.location.city}, ${viewJobItem.location.region}, ${viewJobItem.location.country}. ${viewJobItem.department}`}</p>
          <div className={styles.jobListingCopy}>
            <div dangerouslySetInnerHTML={{ __html: viewJobItem.full_description }} />
          </div>
          <Button className={styles.jobListingButton} theme="purple"><a href={viewJobItem.application_url}>Apply for this job</a></Button>
        </Popup>
      )}
    </Layout>
  )
}

export default Jobs

export const query = graphql`{
  allWorkableJob {
    edges {
      node {
        title,
        url,
        benefits,
        department,
        application_url,
        full_description,
        location {
          city,
          country,
          region,
        }
      }
    }
  }
}`