import React, { Fragment } from "react"

import cx from "classnames"
import styles from "./styles.module.scss"

const HeroCopyBlock = ({
  title,
  buttons,
  children
}) => (
  <div className={cx('text-white', styles.heroCopyBlock)}>
    <h1 className="display underline">
      {title.map(item => (
        <Fragment><span>{ item }</span><br/></Fragment>
      ))}
    </h1>
    <div className={styles.heroCopyContent}>
      { children }
    </div>
    {buttons && (
      <div className={cx(styles.buttons, styles[`buttons${buttons.length}`])}>
        {buttons.map((button, i) => (
          <div key={i}>
            { button }
          </div>
        ))}
      </div>
    )}
  </div>
);

export default HeroCopyBlock
