import React from "react"

import styles from "./styles.module.scss"
import cx from "classnames"

const PrimaryHero = ({
  theme,
  img,
  className,
  nav,
  children
}) => (
  <div className={cx(styles.hero, styles[theme], className && className)}>
    <div className="container-large">
      <div className={styles.container}>
        { children }
      </div>
    </div>
    {img && (
      <div className={styles.heroImg}>
        <div className={styles.heroImgLeft} />
        <div className={styles.heroImgRight} />
        <img src={img} alt="hero descriptive" />
      </div>
    )}
  </div>
);

export default PrimaryHero
