import React from "react"

import styles from "./styles.module.scss"

const Popup = ({
  close,
  children
}) => (
  <div className={styles.popupBg}>
    <div className={styles.popup}>
      {close && (<span className={styles.close} onClick={close} />)}
      { children }
    </div>
  </div>
)

export default Popup
