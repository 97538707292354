import React from "react"

import cx from "classnames"
import styles from "./styles.module.scss"

const Spacer = ({
  sides,
  size,
  children,
  className,
}) => (
  <div className={cx(styles.spacer, styles[size], styles[sides], className && className)}>
    { children }
  </div>
)

export default Spacer
