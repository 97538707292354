import React from "react"

import styles from "./styles.module.scss"
import cx from "classnames"

const SecondaryHero = ({
  theme,
  className,
  children
}) => (
  <div className={cx(styles.hero, styles[theme], className && className)}>
    <div className="container-large">
      <div className={styles.container}>
        { children }
      </div>
    </div>
  </div>
);

export default SecondaryHero
